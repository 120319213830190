import Article from "../components/Article";
import Breadcrumbs from "../components/Article//Breadcrumbs";
import Headline from "../components/Article/Headline";
import React from "react";
import { Link } from "gatsby";
import { ThemeContext } from "../layouts";

const Success = props => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <Breadcrumbs slug="/Success" title={null} theme={theme} />
            <header>
              <Headline title="Thanks for getting in touch!" theme={theme} />
              <h2> Useful links </h2>
            </header>
            <br />
            <Link to="/">
              <li> 🏠 Home </li>
            </Link>
            <Link to="/article/">
              <li> 📰 Articles </li>
            </Link>
          </Article>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

export default Success;
